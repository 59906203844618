// Modules
import axios from 'axios';
import { useEffect, useState } from 'react';

// Environment variables
import { environment } from '../../environment';

// Components
import { ClockComponent } from '../clock/clock.component';
import { ErrorsComponent } from '../errors/errors.component';
import { LoaderComponent } from '../loader/loader.component';

// Types
import { ErrorsType } from '../errors/errors.type';
import { AuthProvider, Client, ClientProviders, LoginProperties } from './login.properties';

// Styles
import './login.styles.scss';
import { useTranslation } from 'react-i18next';
import { UserHelper } from '../../helpers/UserHelper';
import MicrosoftButton from './providers/microsoft.button';
import GoogleButton from './providers/google.button';
import PasswordInput from '../password/password-input.component';
import { UserSettingsHelper } from '../../helpers/UserSettingsHelper';

/**
 * Login Component
 * @description Component to login in the platform
 * @param {LoginProperties} properties
 * @returns {JSX.Element}
 */
export function LoginComponent(properties: LoginProperties): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // errors of login request
    const [errors, setErrors] = useState<ErrorsType>({})

    // email of user
    const [email, setEmail] = useState<string>('')

    // password of user
    const [password, setPassword] = useState<string>('')

    const [enableProvider, setEnableProvider] = useState<boolean>(false);

    /*
      id -> auth_providers_group in hasura
      provider -> auth_providers in hasura
    */
    const [providers, setProviders] = useState<{
      id: ClientProviders['id']
      name: AuthProvider['name']
    }[]>([{ id: '', name: 'local' }]);

    const [clients, setClients] = useState<Client[]>([]);

    // loading of axios request
    const [loading, setLoading] = useState<boolean>(false)

    const loginSubmit = () => {

        // show loading
        setLoading(true)

        // clean errors
        setErrors({})

        // login axios request
        axios.post(`${environment.api.host}/api/auth/login`, {
            email: email,
            password: password
        }).then((response) => {

            // delay to show success
            setTimeout(async () => {

                // hide loading
                setLoading(false)

                // get user
                const user = response.data.items[0];

                // save user in local storage
                localStorage.setItem('user', JSON.stringify(user))

                // get user settings
                const userSettings = await UserSettingsHelper.getUserSettings(user);

                // save user settings in local storage
                localStorage.setItem('user_settings', JSON.stringify(userSettings));

                // set user settings in app state
                properties.setUserSettings(userSettings);

                // save canRecord in localStorage
                localStorage.setItem('canRecord', 'false');

                // set user in app state
                properties.setUser(user)

                // Update last login in API
                UserHelper.updateUserLastLogin(user)
                  .then(() => {
                    console.log(`[Login]: Login at ${new Date().toISOString()}`)
                  }).catch((error) => {
                      console.error('Error updating last login', error)
                  });

            }, 200);
        })
            .catch((error) => {

                // delay to show error
                setTimeout(() => {

                    // hide loading
                    setLoading(false)

                    // get errors
                    const errors = error?.response?.data?.errors;

                    // set request errors
                    if (errors) setErrors(errors)

                    // define general error
                    else setErrors({ general: ['Error de conexión'] })

                }, 200);
            })
    }

    const getAllClients = async (domain?: string) => {
      try {
        const response = await axios.get(`${environment.api.host}/api/client/all`, {
          params: {
            domain: domain
          }
        });

        if (!response.data.items) {
          setClients([]);
          return;
        }

        setClients(response.data.items);
      } catch (error) {
        console.error('[Login]: Error getting clients from API', error);

        setClients([]);
      }
    }

    const detectEmailDomain = (email: string) => {
      const domain = email.split('@')[1];

      if (!domain) {
        setEnableProvider(false);
        setProviders([{ id: '', name: 'local' }]);
        return;
      }

      if (clients.length > 0) {
        const client = clients.find((client) => {
          return client.auth_providers_clients.find((provider) => {
            return provider.domains.includes(`@${domain}`) || provider.domains.includes(domain);
          });
        });

        if (!client) {
          setEnableProvider(false);
          setProviders([{ id: '', name: 'local' }]);
          return;
        }

        const providers = client.auth_providers_clients.filter((provider) => {
          return provider.domains.includes(`@${domain}`) || provider.domains.includes(domain);
        });

        setEnableProvider(true);
        setProviders(providers.map((provider) => {
          return {
            id: provider.id,
            name: provider.auth_provider.name
          }
        }));
      } else {
        setEnableProvider(false);
        setProviders([{ id: '', name: 'local' }]);
      }

    }

    const handleMicrosoftLogin = async (provider: {
      id: ClientProviders['id']
      name: AuthProvider['name']
    }) => {
      try {

        const authProviderId = encodeURIComponent(provider.id);

        // Build Microsoft login URL and redirect to it
        window.location.href = `${environment.api.host}/api/auth/microsoft?auth_provider_id=${authProviderId}`;

        //const response = await axios.get(`${environment.api.host}/api/auth/microsoft?auth_provider_id=${authProviderId}`);

      } catch (error) {
        console.error(`[Login]: Error getting Microsoft login URL`, error);
      }
    };

    useEffect(() => {
      getAllClients();
    }, []);

    return <div className="LoginComponent " onKeyUp={(e) => {
        if (e.key === 'Enter') loginSubmit()
    }}>

        {/* Form to Login in Sippar */}
        <div className='box'>
            <h2>
                {t('login.title.start')} <b>Sippar</b> {t('login.title.end')}
            </h2>
            <ErrorsComponent errors={errors} name="general" />

            <div className='Input'>
                <i className="las la-envelope"></i>
                <input type="text" placeholder={`${t('login.email')}`}
                    onChange={(event) => {
                        setEmail(event.target.value)
                        detectEmailDomain(event.target.value);
                    }}
                />
            </div>
            <ErrorsComponent errors={errors} name="email" />

            <PasswordInput name={'password'} providers={providers} placeholder={`${t("login.password")}`} setPassword={setPassword} />
            <ErrorsComponent errors={errors} name="password" />
            <ErrorsComponent errors={errors} name="credentials" />

            {/* Local Login */}
            {
              providers.some((provider) => {return provider.name === 'local'}) && (
                <div className='Submit' onClick={loginSubmit}>
                  {t('login.submit')}
                </div>
              )
            }

            {/* Providers Login */}
            {
              enableProvider && providers && (
                <div style={{ marginTop: 20 }}>
                {
                  providers.map((provider) => {
                    switch (provider.name) {
                      case 'microsoft':
                        return (
                          <div className={`${provider.name}`} key={provider.name}>
                            <MicrosoftButton onClick={(e) => {
                              console.log(`[Login]: Microsoft login`)

                              handleMicrosoftLogin(provider);

                            }} />
                          </div>
                        );

                      case 'google':
                        return (
                          <div className={`${provider.name}`} key={provider.name}>
                            <GoogleButton onClick={(e) => {
                              console.log(`[Login]: Google login`)
                            }} />
                          </div>
                        )

                      default:
                        return null;
                    }
                  })
                }
                </div>
              )
            }

            <div className='Alternative'>
                {
                    t('login.alternative')
                }
            </div>
            <div className='Submit secondary' onClick={() => { properties.setScene('register') }}>
                {t('login.register')}
            </div>
            <div className='Alternative link' onClick={() => { properties.setScene('forgot') }}>
                {t('login.forgot')}
            </div>
            <LoaderComponent status={loading} />
        </div>

        {/* Clock to Render in Home Screen */}
        <ClockComponent />

    </div>
}
