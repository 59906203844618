// Modules
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDebounce } from 'use-debounce';
import Moment from 'react-moment';
import moment from 'moment';

// Properties
import { ScheduleProperties } from './schedule.properties';

// Components
import { LoaderComponent } from '../loader/loader.component';
import { ScheduleDeleteComponent } from './schedule.delete';
import { MeetPatientComponent } from './schedule.select';
import { ScheduleUpdateComponent } from './schedule.update';

// Types
import { MeetType } from './schedule.types';

// Environment variables
import { environment } from '../../environment';

// Styles
import './schedule.styles.scss';
import { useTranslation } from 'react-i18next';
import { UserSettingsType } from '../../types/user_settings.type';

/**
 * Schedule Component
 * @description Manager of quotes from Sippar
 * @param {ScheduleProperties} properties
 * @returns {JSX.Element}
 */
export function ScheduleComponent(properties: ScheduleProperties): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // meet selected to update or delete
    const [meetSelected, setMeetSelected] = useState<MeetType>();

    // list of meets
    const [meets, setMeets] = useState<MeetType[]>([]);

    // type of meet
    const [type, setType] = useState<string>('future');

    // screen to show
    const [screen, setScreen] = useState<string>('list');

    // search text
    const [search, setSearch] = useState<string | undefined>();

    // loading status of request
    const [loading, setLoading] = useState<boolean>(false);

    // search text debounced
    const [searchText] = useDebounce(search, 1000);

    // direct participants
    const [directParticipants, setDirectParticipants] = useState<boolean>(false);

    const [days, setDays] = useState<{
        [key: string]: MeetType[]
    }>({});

    const [userSettings, setUserSettings] = useState<UserSettingsType|undefined>(properties.userSettings);

    const getData = () => {

        // show loading
        setLoading(true)

        // path of endpoint to get meets
        let path = `${environment.api.host}/api/meet?type=${type}`;

        // if search text is defined
        if (search) path = `${path}?search=${searchText}`

        // get meets
        axios.get(path, {
            headers: {
                Authorization: `Bearer ${properties.user.access_token}`
            }
        }).then((response) => {

            // delay to hide loading
            setTimeout(() => {

                // hide loading
                setLoading(false)

                // set meets
                const meets = response.data.items;
                setMeets(meets)

                // split by days
                splitByDays(meets)

            }, 1000);

        })
            .catch((error) => {

                // hide loading
                setLoading(false)

            })
    }

    // execute when search or type changes
    useEffect(() => {

        // get data
        getData();

    }, [searchText, type])

    const splitByDays = (meets: MeetType[]) => {
        // store of days
        const days: any = {};

        // split by days
        meets.forEach((meet) => {

            const date = new Date(meet.started_at);
            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();
            const key = `${day}-${month}-${year}`;

            const _meet = {
                ...meet,
                started_at: moment(meet.started_at),//.format('DD/MM/YYYY HH:mm'),
                finished_at: moment(meet.finished_at),//.format('DD/MM/YYYY HH:mm')
            }
            if (days[key]) {
                days[key].push(_meet);
            } else {
                days[key] = [_meet];
            }

        })

        // set days
        setDays(days)
    }


    return <div className="ScheduleComponent Modal">

        <div className='Header'>
            {t('schedule.title')}
            {
                userSettings?.is_doctor &&
                <span className='add' onClick={() => { setScreen('insert') }}>{t('schedule.add')}</span>
            }
            <i className={`las la-times ${!userSettings?.is_doctor ? 'last' : ''}`} onClick={() => { properties.setScene(undefined) }}></i>
        </div>
        <div style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflowY: 'auto'
        }}>
            <LoaderComponent status={loading} />

            <div className='Tabs'>
                <div className={`Tab ${type === 'future' ? 'active' : ''}`}
                    onClick={() => {
                        setType('future')
                    }}
                >
                    {t('schedule.future')}
                </div>
                <div className={`Tab ${type === 'past' ? 'active' : ''}`}
                    onClick={() => {
                        setType('past')
                    }}
                >
                    {t('schedule.past')}
                </div>

            </div>
            <div className='Search'>
                <i className="las la-search"></i>
                <input type="text" placeholder={`${t('schedule.search')}...`}
                    value={search}
                    onChange={(event) => {
                        setSearch(event.target.value)
                    }}
                />
            </div>
            {
                !loading && meets.length === 0 && <div className='Empty'>
                    <i className="las la-grin-beam-sweat"></i>
                    <p>{
                        t('schedule.empty')
                    }</p>
                </div>
            }

            {
                !loading && Object.keys(days).map((key, index) => {
                    return <div key={index} className='Day'>
                        <div className='title' style={{
                            display: 'flex',
                            alignItems: 'center'

                        }}>
                            <Moment
                                locale='es'
                                format='dddd D'
                            >{days[key][0].started_at}</Moment>
                            <b style={{
                                fontSize: 16,
                                marginLeft: 'auto'
                            }}>
                                <Moment
                                    locale='es'
                                    format='MMMM, YYYY'
                                >{days[key][0].started_at}</Moment>
                            </b>
                        </div>
                        <ul className='List compact'>



                            {
                                days[key].map((meet, index) => {
                                    return <li key={index}>
                                        {
                                            userSettings?.is_doctor && <i className='las la-pen'
                                                onClick={() => {
                                                    setMeetSelected(meet);
                                                    setScreen('update')
                                                }}
                                            ></i>
                                        }
                                        {type === 'future' && <i className='las la-video'
                                            onClick={() => {
                                                properties.setRoom(meet.id)
                                                properties.setScene('room')
                                            }}
                                        ></i>}
                                        <div className="user">
                                            <div className="name">
                                                {meet.patient?.name} {meet.patient?.surnames}
                                            </div>
                                            <div className="document">
                                                <Moment
                                                    format='DD/MM/YYYY'
                                                >{meet.started_at}</Moment>
                                                <b><Moment
                                                    format='HH:mm'
                                                >{meet.started_at}</Moment> - <Moment
                                                    format='HH:mm'
                                                >{meet.finished_at}</Moment></b>
                                            </div>
                                        </div>


                                        {
                                            userSettings?.is_doctor && <i className='las la-trash-alt'
                                                onClick={() => {
                                                    setMeetSelected(meet);
                                                    setScreen('delete')
                                                }}></i>
                                        }
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                })
            }
        </div>

        {
            // screen to insert new meet
            screen === 'insert' && <MeetPatientComponent
                user={properties.user}
                openParticipants={(data) => {
                    setScreen('update')
                    setMeetSelected(data);
                    setDirectParticipants(true);
                }}
                setScreen={setScreen}
                updateData={() => {
                    search ? setSearch(undefined) : getData();
                }}
            />
        }
        {
            // screen to delete meet
            screen === 'delete' && meetSelected?.id && <ScheduleDeleteComponent
                user={properties.user}
                setScreen={setScreen}
                id={meetSelected?.id}
                updateData={() => {
                    search ? setSearch(undefined) : getData();
                    setDirectParticipants(false);
                }}
            />
        }
        {
            // screen to update meet
            screen === 'update' && meetSelected?.id && <ScheduleUpdateComponent
                user={properties.user}
                setScreen={setScreen}
                subscreen={directParticipants ? 'participants' : undefined}
                deselect={() => {
                    setMeetSelected(undefined)
                    setDirectParticipants(false);
                }}
                updateData={() => {
                    search ? setSearch(undefined) : getData();
                }}
                meet={meetSelected}
            />
        }
    </div>

}
