// Modules
import { toast } from "react-toastify"
import { Socket } from "socket.io-client"

// Types
import { UserType } from "../../../types/user.type"
import { AvailableType, SocketAvailableEventsInterface, } from "../dish.types"

// Constants
let firstSocket = false;

/**
 * Socket Available
 * @description Socket to listen availables
 * @param {UserType} user
 * @param {Socket} socket
 * @param {SocketAvailableEventsInterface} events
 * @returns {void}
 */
export class SocketAvailable {

    // users availables
    availables: AvailableType[] = []

    constructor(properties: {
        user: UserType,
        socket: Socket,
        events: SocketAvailableEventsInterface
    }) {

        // emit hi to rest of users
        properties.socket.emit('hi', {
            user: { ...properties.user },
        })

        // listen welcome
        properties.socket.on('welcome', (data: any) => {

            // check available have user.id
            let correct = 0;

            data?.availables?.forEach((available: AvailableType) => {
                if (properties.user.id === available.user.id) {
                    // remove
                    if (correct === 1 && firstSocket === false) {
                        properties.events.askCloseSession()
                    }
                    correct++;

                }
            })

            firstSocket = true;
            correct && properties.events.onAvailables(data?.availables)

        })

        // listen update availables
        properties.socket.on('update-availables', (data: any) => {
            properties.events.onAvailables(data?.availables)

        })

        // listen close session
        properties.socket.on('close-session', (data: any) => {
            properties.events.closeSession()
        })

        // listen user accepted your call
        properties.socket.on('user-accepted-your-call', (data: any) => {
          toast("Ha aceptado tu llamada")
        })

        // listen room created
        properties.socket.on('room-created', (data: any) => {

            toast("Reunión creada")
            properties.events.onRoom(data.room)

        })

        // listen call to you
        properties.socket.on('call-to-you', (data: any) => {
            toast("Te están llamado", data)
            properties.events.onCall(data)

        })

        // listen call rejected to you
        properties.socket.on('call-rejected-to-you', (data: any) => {
            toast("Ha rechazado la llamada", data)
            properties.events.onRejected(data)

        })

        // change layout
        properties.socket.on('change-layout', (data: any) => {
          properties.events.onChangeLayout(data);
        })

        // remove stream
        properties.socket.on('remove-stream', (data: any) => {
          properties.events.onRemoveStream(data);
        })

        // change stream status
        properties.socket.on('change-stream-status', (data: any) => {
          properties.events.onChangeStreamStatus(data.streamId, data.status, data.type);
        })

        // Change stream track status
        properties.socket.on('change-stream-track-status', (data: {
          roomId?: string,
          streamId: string,
          streamType?: string,
          trackId: string,
          status: boolean,
          type: 'audio' | 'video'
        }) => {
          console.log('change-stream-track-status', data);

          properties.events.onChangeStreamTrackStatus(data.streamId, data.trackId, data.status, data.type, data.roomId, data.streamType);
        })
    }
}
