import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import successAnimation from "../../lotties/success.json";
import failAnimation from "../../lotties/fail.json";
import warningAnimation from "../../lotties/warning.json";

import "./modal.styles.scss";

export interface ModalProps {
  isOpen: boolean;
  type: "success" | "error" | "warning";
  onClose: () => void;
  children?: React.ReactNode;
  body?: {
    title: string;
    content: string;
  };
}

export function Modal({ isOpen, type, onClose, body, children }: ModalProps) {
  const [showModal, setShowModal] = useState(isOpen);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  if (!showModal) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className={`modal-content`}
        style={{ width: "30%" }}
        onClick={(e) => e.stopPropagation()}
      >
        {children ? (
          children
        ) : (
          <div className="modal-content__default">
            {type === "success" && (
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: successAnimation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={150}
                width={150}
              />
            )}

            {type === "error" && (
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: failAnimation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={150}
                width={150}
              />
            )}

            {type === "warning" && (
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: warningAnimation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={150}
                width={150}
              />
            )}

            <br />
            <h2 style={{ textAlign: "center" }}>{body?.title}</h2>
            <br />
            <p style={{ textAlign: "center" }}>{body?.content}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Modal;
