import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; // Suponiendo que usas react-i18next para traducciones

interface InputProps {
  name: string;
  providers: Array<{ name: string }>;
  placeholder: string;
  checkPassword?: boolean;
  submitAttempt?: boolean;
  setPassword: (password: string) => void;
}

export function PasswordInput(props: InputProps): JSX.Element {
  const { t } = useTranslation();

  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [showPasswordInfo, setShowPasswordInfo] = useState(false);

  const isLocalProviderEnabled = props.providers.some(
    (provider) => provider.name === "local"
  );

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

   // Comprobar dinámicamente la contraseña
   const [passwordLength, setPasswordLength] = useState(false);
   const [passwordUppercase, setPasswordUppercase] = useState(false);
   const [passwordLowercase, setPasswordLowercase] = useState(false);
   const [passwordNumber, setPasswordNumber] = useState(false);
   const [passwordSpecial, setPasswordSpecial] = useState(false);
   const [correctPassword, setCorrectPassword] = useState(false);

   useEffect(() => {
     if (password) {
       const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/)


       setPasswordLength(password.length >= 8);
       setPasswordUppercase(/[A-Z]/.test(password));
       setPasswordLowercase(/[a-z]/.test(password));
       setPasswordNumber(/[0-9]/.test(password));
       setPasswordSpecial(/[$@$!%*?&]/.test(password));
       setCorrectPassword(regex.test(password));
     } else {
         setPasswordLength(false);
         setPasswordUppercase(false);
         setPasswordLowercase(false);
         setPasswordNumber(false);
         setPasswordSpecial(false);
         setCorrectPassword(false);
     }
   }, [password])

  return (
    <>
    <div className="Input">
      <i className="las la-key"></i>
      <input
        name={props.name}
        type={showPassword ? "text" : "password"}
        placeholder={props.placeholder}
        disabled={!isLocalProviderEnabled}
        readOnly={!isLocalProviderEnabled}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          props.setPassword(e.target.value);
        }}
        onFocus={(e) => {
          setShowPasswordInfo(true);
        }}
        onBlur={(e) => {
          setShowPasswordInfo(false);
        }}
      />
      <i
        className={`show-password las ${showPassword ? "la-eye" : "la-eye-slash"}`}
        onClick={togglePasswordVisibility}
      ></i>
    </div>
    {
      props.checkPassword && !props.submitAttempt && !correctPassword && showPasswordInfo && <small>
        {t('register.password.info')}
        <ul>
          { !passwordLength && <li>{t('register.password.info.length')}</li> }
          { !passwordUppercase && <li>{t('register.password.info.uppercase')}</li> }
          { !passwordLowercase && <li>{t('register.password.info.lowercase')}</li> }
          { !passwordNumber && <li>{t('register.password.info.number')}</li> }
          { !passwordSpecial && <li>{t('register.password.info.special')}</li> }
        </ul>
      </small>
    }
    </>
  );
}

export default PasswordInput;
