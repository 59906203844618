import React, { useEffect, useState } from "react";

import "./dish.video.profile.scss";
import DishMutedWidget from "../mutedWidget/mutedWidget.component";

interface ProfileProps {
  name?: string;
  isNotDeviceProfile?: boolean;
  className?: string;
}

export function DishVideoProfile(props: ProfileProps) {
  useEffect(() => {
    if (props.isNotDeviceProfile) {
      localStorage.setItem('audio_status', 'false');
      localStorage.setItem('video_status', 'false');
    }
  }, []);

  return (

    <div className={`${props.className ?? ''} DishVideoProfile ${props.isNotDeviceProfile ? 'DishVideoComponent' : ''}`}>
      <div className="title">
        <span>{props.name}</span>
      </div>
      {
        props.isNotDeviceProfile && <DishMutedWidget />
      }
    </div>

  );
}

export default DishVideoProfile;
