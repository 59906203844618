import React from "react";

import "./dish.video.recording.scss";
import { t } from "i18next";

interface DishVideoRecordingProperties {
  layout?: string;
}

export function DishVideoRecording(props: DishVideoRecordingProperties) {
  return (
    <div className={`DishVideoRecording ${props.layout || ""}`}>
      <div>
        <i className="las la-dot-circle" />
        <p>{t("recording.title")}</p>
      </div>
    </div>
  );
}

export default DishVideoRecording;
