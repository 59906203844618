// Modules
import { useRef, useState } from 'react';
import Datetime from 'react-datetime';
import 'moment/locale/es';
import axios from 'axios';

// Types
import { MeetType as PatientType } from './schedule.types';
import { UserType } from '../../types/user.type';
import { ErrorsType } from '../errors/errors.type';

// Components
import { ErrorsComponent } from '../errors/errors.component';
import { LoaderComponent } from '../loader/loader.component';

// Environment variables
import { environment } from '../../environment';

// Styles
import './schedule.styles.scss';
import { useTranslation } from 'react-i18next';
import { use } from 'i18next';

/**
 * Schedule Insert Component
 * @description Screen to insert a schedule in the platform
 * @returns {JSX.Element}
 */
export function ScheduleInsertComponent(properties: {
    setScreen: (screen: string) => void
    user: UserType
    updateData: () => void
    deselect: () => void
    patient?: PatientType
    openParticipants: (meet: any) => void
}): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // errors in insert request
    const [errors, setErrors] = useState<ErrorsType>({})

    // success in insert request
    const [success, setSuccess] = useState<boolean>(false)

    // key to force re-render
    const [random, setRandom] = useState<string>('start')

    // loading of axios request
    const [loading, setLoading] = useState<boolean>(false)

    // list inner ref to scroll to top
    const listInnerRef = useRef<HTMLDivElement>(null);

    // data to insert
    const [data, setData] = useState<any>({})

    const onSubmit = () => {

        // show loading
        setLoading(true)

        // clean errors
        setErrors({})

        let apiUrl = '';
        let dataObject = {};
        const requestConfig = {
          headers: {
            Authorization: `Bearer ${properties.user.access_token}`
          }
        };


        if (properties.patient) {
          apiUrl = `${environment.api.host}/api/meet/`;
          dataObject = { ...data, patient_id: properties.patient.id };
        } else {
          dataObject = { ...data, user_id: properties.user.id };
          apiUrl = `${environment.api.host}/api/meet/fast`;
        }

        // insert schedule by axios
        axios.post(apiUrl, dataObject, requestConfig)
            .then((response) => {

                // delay to show success
                setTimeout(() => {

                    // hide loading
                    setLoading(false)

                    // show success
                    setSuccess(true)

                    // scroll to top
                    listInnerRef.current?.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })

                    // update data
                    properties.updateData();

                    // set random key to force re-render
                    setRandom(Math.random().toString())

                    // open participants
                    properties.openParticipants(response.data.items[0])

                    setData({})

                }, 1000);
            })
            .catch((error) => {

                // delay to show errors
                setTimeout(() => {

                    // hide loading
                    setLoading(false)

                    // scroll to top
                    setRandom(Math.random().toString())

                    // get errors
                    const errors = error?.response?.data?.errors;

                    console.log('Errores', errors);

                    // set errors
                    if (errors) setErrors(errors)

                    // define general error
                    else setErrors({ general: ['Error de conexión'] })

                }, 1000);
            })
    }

    return <div className="ScheduleComponent Modal">

        <div className='Header'>
            <i
                className='las la-angle-left'
                onClick={() => {
                    properties.deselect()
                    properties.setScreen('list')
                }}
            />
            <b>
                {t('schedule.insert.back')}
            </b>
        </div>
        <div
            className='Form'
            ref={listInnerRef}
            key={random}
            style={{
                paddingTop: 10,
            }}
        >
            {
                success && <div className='Success'>
                    <i className="las la-check"></i>
                    <p>{t('schedule.insert.success')}</p>
                </div>
            }
            <ErrorsComponent errors={errors} name="meet_creation" />
            <p>{t('schedule.insert.subject')}*</p>
            <div className='Input'>
                <i className="las la-quote-left"></i>
                <input
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                        setData({ ...data, subject: event.target.value })
                    }}
                    value={data.subject}
                />
            </div>
            <ErrorsComponent errors={errors} name="subject" />
            <p>{t('schedule.insert.start')}*</p>
            <div className='Calendar'>

                <Datetime
                    locale="es"
                    input={false}
                    initialValue={data.started_at}

                    onChange={(date) => {

                        setData({ ...data, started_at: date })
                    }}
                />
            </div>
            <ErrorsComponent errors={errors} name="started_at" />
            <p>{t('schedule.insert.end')}*</p>
            <div className='Calendar'>
                <Datetime
                    input={false}
                    locale="es"
                    initialValue={data.finished_at}
                    onChange={(date) => {
                        setData({ ...data, finished_at: date })
                    }}
                />
            </div>
            <ErrorsComponent errors={errors} name="finished_at" />
            <div className='Space' />
            <div className='Submit-position'>
                <div className='Submit' onClick={onSubmit} >
                    {t('schedule.insert.submit')}
                </div>
            </div>
        </div>
        <LoaderComponent status={loading} />
    </div>

}
