import { UserSettingsHelper } from "../../../../../../../../helpers/UserSettingsHelper";
import { MutedProperties } from "./muted.properties";

/**
 * Muted Dish Component
 * @description Component to mute or unmute a video
 * @param {MutedProperties} properties
 * @returns {JSX.Element}
 */
export function MutedDishComponent(properties: MutedProperties): JSX.Element {

    const button = UserSettingsHelper.getUserSettingsButton(properties.userSettings, 'video.muted');

    const activeIcon = button?.icons?.active || 'las la-volume.up';
    const inactiveIcon = button?.icons?.inactive || 'las la-volume-off';

    // NOTE: If the "Estetoscope" button is a remote stream, it will not be displayed
    // the button to mute or unmute the video. A remote stream means that the video
    // is being received from another user
    if (properties.streamType === 'remote') return (<></>);

    // If button is defined in the user settings
    if (button) {
        if (!button.visible) return (<></>);
        return <button onClick={() => properties.setIsMuted(!properties.isMuted)}>
            {properties.isMuted
                ? <i className={inactiveIcon} />
                : <i className={activeIcon} />
            }
        </button>
    }

    return <button onClick={() => properties.setIsMuted(!properties.isMuted)}>
        {properties.isMuted
            ? <i className={inactiveIcon} />
            : <i className={activeIcon} />
        }
    </button>

}