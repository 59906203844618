// Modules
import { useEffect } from "react";

// Properties
import { CallProperties } from "./call.properties"
import { useTranslation } from "react-i18next";
import { CountdownComponent } from "../countdown/countdown.component";
import { UserType } from "../../../../types/user.type";

// Assets
const boopSfx = require('./call.mp3');

/**
 * Call Component
 * @description Component to show the call
 * @param {CallProperties} properties
 * @returns {JSX.Element}
 */
export function CallComponent(properties: CallProperties): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // Get logged user
    const user: UserType = properties.user    

    /**
     * Accepted Call
     */
    const acceptedCall = () => {
        // emit call accepted
        properties.socket.emit('call-accepted', {
            room: properties.call.room,
            user: properties.user,
            author: properties.call.available,

        })

        // join to room
        properties.socket.emit('join-to-room', {
            room: properties.call.room,
            user: properties.user,

        })

        // set room to call
        properties.setRoom(properties.call.room)
        setTimeout(() => {
            properties.socket.joinRoom(properties.call.room)
            properties.setCall(undefined)
        }, 1000)
    }

    /**
     * Rejected Call
     */
    const rejectedCall = () => {
        // emit call rejected
        properties.socket.emit('call-rejected', {
            room: properties.call.room,
            available: properties.call.available,
        })

        // clean call
        properties.setCall(undefined)
    }

    useEffect(() => {
        // emit audio notification
        const audio = new Audio(boopSfx);
        audio.play();

        setTimeout(() => {
            // repeat audio notification
            const audio = new Audio(boopSfx);
            audio.play();
        }, 1000);

        console.log(user)

    }, [properties.call, user])

    return <div className="CallComponent Modal" style={{
        right: 0,
        left: 'auto'
    }}>
        <div className='Header'>
            {t('call.title')}
            <i className='las la-times last' onClick={() => { properties.setCall(undefined) }}></i>
        </div>

        <div style={{
            color: '#666',
            padding: 25, fontSize: 18, marginTop: 25, borderLeft: '4px solid #ddd', marginLeft: 25
        }}>
            <b style={{
                color: '#111'
            }}>{
                    properties?.call?.available?.user?.name
                } {
                    properties?.call?.available?.user?.surnames
                }</b> {t('call.message')}
        </div>

        <div style={{
            margin: 25, marginTop: 0
        }}>
            <div className="Submit" onClick={acceptedCall}>
                {t('call.accept')}
                {
                    user.user_setting?.auto_call && properties.call !== undefined ? (
                    <CountdownComponent time={user.user_setting?.auto_call_timeout} onFinish={acceptedCall} />
                    ) : ("")
                }
            </div>
            <div className="Submit secondary" onClick={rejectedCall}>
                {t('call.reject')}
            </div>
        </div>
    </div>
}