// Modules
import axios from "axios";
import { useEffect, useState } from "react";

// Environment
import { environment } from "../../../../environment";

// Components
import { DishResize } from "../room/resize/dish.layout";

// Types
import { SelectorMediaProperties } from "./selectorMedia.properties";
import { SelectorMediaAppType } from "./selectorMedia.types";
import { useTranslation } from "react-i18next";
import { Id, toast } from "react-toastify";

/**
 * Selector Media Component
 * @param {SelectorMediaProperties} properties
 * @returns {JSX.Element}
 */
export function SelectorMediaComponent(properties: SelectorMediaProperties): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // state to avoid multiple clicks in media selector
    const [selected, setSelected] = useState(false)

    // array of devices
    const [apps, setApp] = useState<SelectorMediaAppType[]>([]);

    // get apps from backend
    const getApps = async () => {

        // get apps
        axios.get(`${environment.api.host}/api/group/me`, {
            headers: {
                Authorization: `Bearer ${properties.user.access_token}`
            }
        }).then(async (response) => {

          const groups = response?.data?.items;

          let apps: SelectorMediaAppType[] = [];

          // validate if groups is array and has length (avoid problems with bad json format)
          if (Array.isArray(groups) && groups.length > 0) {
            // get all apps from groups
            groups.forEach((group: any) => {
              group.apps.forEach((app: any) => {
                // Check if app.name is not in array and insert
                if (!apps.find((a) => a.name === app.name)) {
                  apps.push(app)
                }
              })
            })
          }

          setApp(apps)

        })
    }

    useEffect(() => {
        getApps();

        // reload apps every 2 seconds
        const interval = setInterval(() => {
            getApps();
        }, 2000);

        return () => clearInterval(interval);

    }, []);

    const connectNativeSource = (path: string, name: string, appName: string) => {

        const toastLoadingId = toast.loading(t('media.selector.wait', { appName: appName }), {
            autoClose: false,
            isLoading: true
        })

        // @ts-ignore
        if (window.electron) {

            // capture all screen for apps without window name
            if (name === 'Entire Screen') {

                connectSource(name, 0, toastLoadingId, appName, () => {
                    // @ts-ignore
                    window.electron.abrirPrograma(path)

                })

            }

            // capture only app window
            else {

                // @ts-ignore
                window.electron.abrirPrograma(path)
                connectSource(name, 0, toastLoadingId, appName, () => {
                    toast.update(toastLoadingId, {
                        type: toast.TYPE.SUCCESS,
                        render: t('media.selector.success', { appName: appName }),
                        autoClose: 5000,
                        isLoading: false
                    });
                })

            }

        }

    }

    // connect to native source
    const connectSource = async (name: string, times: number, toastId: Id, appName: string, callBack?: () => void) => {

        // @ts-ignore
        window.electron.getSources().then(async (sources: any) => {

            // @ts-ignore
            const app = sources.find((source: any) => source.name === name)

            if (app) {
                try {

                    // get media stream
                    const media = await navigator.mediaDevices.getUserMedia({
                        audio: false,
                        video: {
                            // @ts-ignore
                            mandatory: {
                                chromeMediaSource: 'desktop',
                                chromeMediaSourceId: app.id,
                                minWidth: 1280,
                                maxWidth: 1920,
                                minHeight: 720,
                                maxHeight: 1080
                            }
                        }
                    })

                    // connect stream
                    properties.addStream?.(media)
                    callBack?.()

                }
                catch (e) {

                    console.log('error mediaDevices', e)

                }

            }
            else {

                if (times < 25) {
                    // try to found app again (apps sometimes are slow to open)
                    setTimeout(() => {

                        connectSource(name, times + 1, toastId, appName, function() {
                            toast.update(toastId, {
                                type: toast.TYPE.SUCCESS,
                                render: t('media.selector.success', { appName: appName }),
                                autoClose: 5000,
                                isLoading: false
                            });
                        })

                    }, 1000)
                } else {
                    setSelected(false)
                    toast.update(toastId, {
                        type: toast.TYPE.ERROR,
                        render: t('media.selector.fail', { appName: appName }),
                        autoClose: 5000,
                        isLoading: false
                    });
                }

            }

        })

    }


    return <>
        {
            apps.map((app, index) => {

                // hide apps without electron
                // @ts-ignore
                if (app.bash && !window.electron) return <></>

                return <div
                    className="camera"
                    key={app.id} onClick={
                        async () => {
                            if (selected) return
                            setSelected(true)
                            connectNativeSource(app.bash, app.selector, app.name)
                            new DishResize('DishScenary', 'DishScenary', 'DishVideoComponent').resize();
                            properties.setScene?.(undefined)
                        }
                    }>
                    <i className={'las la-plus'} />
                    <div className="name">{app.name}</div>
                    <div className="model">{app.model}</div>
                    <div className="image" style={{
                        backgroundImage: app.icon ? `url(${app.icon})` : 'unset',
                        width: "45%"
                        }} />
                </div>

            })

        }
    </ >
}
