// Modules
import { useEffect, useState } from "react";
var AudioStreamMeter = require("audio-stream-meter");

/**
 * Audio Component
 * @param {{stream: MediaStream}} properties
 * @returns {JSX.Element}
 */
export const AudioComponent = (properties: {
    stream: MediaStream;
}
) => {

    // volumen
    const [volumen, setVolumen] = useState<number>(0);
    useEffect(() => {

        const [audioTrack] = properties.stream.getAudioTracks();
        if (audioTrack) {

            // audio context
            var audioContext = new AudioContext();
            var mediaStream = audioContext.createMediaStreamSource(properties.stream);

            // is muted?
            if (audioTrack.enabled) {
                var meter = AudioStreamMeter.audioStreamProcessor(
                    audioContext,
                    function () {
                        var volume = meter.volume * 400;
                        setVolumen(volume);
                    }, {
                    volumeFall: 0.5,
                });

                mediaStream.connect(meter);
            }
        }

        return () => {
            mediaStream?.disconnect?.();
        };

    }, [properties.stream]);


    return <i
        style={{
            width: 10,
            alignItems: "flex-end",
            overflow: "hidden",
            marginRight: "auto",
            position: "absolute",
            left: 10,
            bottom: 10,
            backgroundColor: "rgba(0, 0, 0, 1)",
        }}
    >
        <div
            style={{
                width: 10,
                height: volumen,
                borderRadius: 10,
                minHeight: 10,
                backgroundColor: "green",
            }}
        ></div>
    </i>

};

